<div class="upgrade-container">
  <div class="upgrade-label">
    <!-- PARAMETER NORMAL -->
    <m-ui-parameter
      *ngIf="normal"
      [item]="normal"
      [other]="{ amount: normal.income || normal.outcome }"
      [stockView]="STOCK_VIEW.B"
      [lack]="false"></m-ui-parameter>

    <!-- ARROW PARAMETERS -->
    <ng-container *ngIf="parameters">
      <div class="production-container arrow" *ngIf="parameters.income_diff !== undefined || parameters.outcome_diff !== undefined">
        <span class="value-diff">
          {{ parameters.income_diff || parameters.outcome_diff | NumberWithSpaces }}
        </span>
      </div>
    </ng-container>
  </div>

  <!-- PARAMETER UPGRADE/NEW -->
  <div class="upgrade">
    <m-ui-parameter
      *ngIf="upgrade"
      [item]="upgrade"
      [other]="{ amount: upgrade.income || upgrade.outcome }"
      [stockView]="STOCK_VIEW.B"
      [lack]="false"
      [ngClass]="{
        'quantity-diff': parameters && (parameters.income_diff || parameters.outcome_diff),
        'decrease': parameters && parameters.income_diff < 0
      }"
    ></m-ui-parameter>

    <!-- NEW INDICATOR -->
    <div class="new-indicator" *ngIf="parameters && parameters.new">
      <i class="fas fa-exclamation exclamation-new"></i>
      <span class="new">NEW</span>
    </div>
  </div>
</div>
